.card{
	border: 0 solid transparent;
}
.card-header,
.card-footer{
	padding-left: .75rem !important;
	padding-right: .75rem !important;
}
.card-header{
	border-bottom: 0 solid transparent;
}
.card-body{
	padding: 1rem;
	p{
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	.tekst{
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.card-footer{
	overflow: hidden;
	border-top: 0 none transparent;
	border-bottom: 3px solid $gray-400;
	background-color: $gray-200;
}
.card-img{
	border-radius: 0;
}